import { useEffect } from 'react';
// import { useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store/baseHooks';
import {
  addPanel,
  onComponentChangeOrBreakpoint,
  removePanel,
  setPanelClosed,
  setPanels
} from 'src/store/slices/uiFlowSlice';

export interface AdditionalDataPanel {
  id: string;
  open?: boolean;
  fixed?: boolean;
  componentName: string;
  name?: string;
  title?: string;
  closeCallback?: () => void;
}

/**
 * For managing the registration and state of flow, tab, and field level additional data panels.
 *
 */
export const useAdditionalDataPanel = () => {
  let timeout: NodeJS.Timeout;
  const PANEL_TOGGLE_TIMEOUT = 300;

  // const isOver900px = useMediaQuery('(min-width: 900px)');

  const dispatch = useAppDispatch();
  const additionalDataPanels = useAppSelector(state => state.uiflow.additionalDataState.additionalDataPanels);
  const sidePanelClosed = useAppSelector(state => state.uiflow.additionalDataState.sidePanelClosed);

  /**
   * Set entire additional panel array
   *
   * @param panels
   */
  const setAdditionalDataPanels = (panels: AdditionalDataPanel[]) => {
    const payload = panels.map(panel => ({ open: false, fixed: false, name: '', title: '', ...panel }));

    dispatch(setPanels(payload));
  };

  /**
   * Add a single panel to panel array
   *
   */
  const addAdditionalDataPanel = (panel: AdditionalDataPanel) => {
    dispatch(addPanel(panel));
  };

  /**
   * Remove a single panel from panel array
   *
   */
  const removeAdditionalDataPanel = (id: string) => {
    dispatch(removePanel(id));
  };

  /**
   * Reset panel array to []
   *
   */
  const resetAdditionalDataPanels = () => {
    dispatch(setPanels([]));
  };

  /**
   * Set the sidePanelClosedProperty
   *
   * @param val
   */
  const setSidePanelClosed = (val: boolean) => {
    dispatch(setPanelClosed(val));
  };

  /**
   * Close any open side panels
   *
   */
  const closeAnyOpenPanels = () => {
    const payload = additionalDataPanels.map((panel: AdditionalDataPanel) => ({ ...panel, open: false }));
    dispatch(setPanels(payload));
  };

  /**
   * Toggle a specific panels open state
   *
   * @param id
   */
  const togglePanel = (id: string) => {
    closeAnyOpenPanels();

    timeout = setTimeout(() => {
      const foundIndex = additionalDataPanels.findIndex((panel: AdditionalDataPanel) => panel.id === id);

      if (foundIndex < 0) return;

      const newPanels: AdditionalDataPanel[] = additionalDataPanels.map((panel: AdditionalDataPanel, index: number) => {
        if (foundIndex === index) {
          return { ...panel, open: !panel.open };
        }

        return { ...panel, open: false };
      });

      if (newPanels.every((panel: AdditionalDataPanel) => !panel.open)) {
        const fixedPanelIndex = additionalDataPanels.findIndex((panel: AdditionalDataPanel) => panel.fixed);

        // if (isOver900px && fixedPanelIndex > -1) {
        if (fixedPanelIndex > -1) {
          newPanels[fixedPanelIndex].open = true;
        }
      }

      setAdditionalDataPanels(newPanels);

      if (newPanels.every(panel => !panel.open)) {
        setSidePanelClosed(true);
      } else {
        setSidePanelClosed(false);
      }
    }, PANEL_TOGGLE_TIMEOUT);
  };

  /**
   * Is panel with provided id in open state
   *
   * @param id string
   * @returns boolean
   */
  const panelIsOpen = (id: string): boolean => {
    const panel = additionalDataPanels.find((panel: AdditionalDataPanel) => panel.id === id);

    if (!panel) return false;

    return panel.open;
  };

  /**
   * Control presence of additional tab panel if window width breakpoint is hit
   * or additional data panel component changes.
   *
   * @param panel AdditionalDataPanel
   */
  const panelComponentChangeOrBreakpointHit = (panel: AdditionalDataPanel) => {
    dispatch(onComponentChangeOrBreakpoint(panel));
  };

  useEffect(() => {
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    setAdditionalDataPanels,
    addAdditionalDataPanel,
    removeAdditionalDataPanel,
    resetAdditionalDataPanels,
    closeAnyOpenPanels,
    togglePanel,
    sidePanelClosed,
    setSidePanelClosed,
    panelArray: additionalDataPanels,
    panelIsOpen,
    panelComponentChangeOrBreakpointHit
  };
};
