import { useEffect } from 'react';
import { useRouter } from 'next/router';
//import { useAuth } from 'react-oidc-context';
//import { useSettings } from 'src/@core/hooks/useSettings';
import { useVigoLogout } from 'src/@core/hooks/useVigoLogout';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const whitelist = ['/dashboards/[name]', '/dashboards/development', '/signedout', '/tracking'];

export const IdleTimer = () => {
  const router = useRouter();
  const minutesBeforeSignout = 20;

  const { handleLogout } = useVigoLogout();

  let timeout: NodeJS.Timeout | null = null;

  const goBackToLogin = () => {
    console.log('sign out page called');
    handleLogout();
  };

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      goBackToLogin();
    }, minutesBeforeSignout * 1000 * 60);
  };

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let preventReset = false;

    if (window.location.origin === 'http://localhost:3000') {
      preventReset = true;
    }

    for (const path of whitelist) {
      if (path === router.pathname) {
        preventReset = true;
      }
    }

    if (preventReset) {
      return;
    }

    restartAutoReset();

    window.addEventListener('mousemove', restartAutoReset);
    window.addEventListener('keydown', restartAutoReset);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener('mousemove', restartAutoReset);
        window.removeEventListener('keydown', restartAutoReset);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  return null;
};
