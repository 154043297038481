import { IconButton, Stack, SvgIconTypeMap, Tooltip, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Menu, ViewGridOutline } from 'mdi-material-ui';
import { Dispatch, SetStateAction, createElement } from 'react';

interface ViewButtonsProps {
  setViewMode: Dispatch<SetStateAction<'list' | 'thumbs'>>;
  viewMode: 'list' | 'thumbs';
}

interface ViewButton {
  viewMode: 'list' | 'thumbs';
  toolTipTitle: string;
  ariaLabel: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
}

const buttons: ViewButton[] = [
  {
    viewMode: 'list',
    toolTipTitle: 'List',
    ariaLabel: 'list',
    icon: Menu
  },
  {
    viewMode: 'thumbs',
    toolTipTitle: 'Thumbs',
    ariaLabel: 'thumbnails',
    icon: ViewGridOutline
  }
];

const ViewButtons = ({ viewMode, setViewMode }: ViewButtonsProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row">
      {buttons.map((button: ViewButton) => (
        <Tooltip
          key={button.ariaLabel}
          title={button.toolTipTitle}
          componentsProps={{ tooltip: { sx: { color: '#fff' } } }}>
          <IconButton
            color="primary"
            aria-label={button.ariaLabel}
            component="label"
            sx={{
              borderRadius: '6px',
              '&:hover': {
                backgroundColor: theme => theme.palette.customColors.primaryGradient,
                '& > *': { color: '#fff' },
                border: 'none',
                outline: 'none'
              }
            }}
            onClick={() => setViewMode(button.viewMode)}>
            {createElement(button.icon, {
              sx: {
                color:
                  viewMode == button.viewMode
                    ? theme => theme.palette.customColors.primaryGradient
                    : theme.palette.mode === 'light'
                    ? '#626679'
                    : '#fff'
              }
            })}
          </IconButton>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default ViewButtons;
