import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';
import { getAccountConfiguration } from 'src/services/account';
import { useSettings } from './useSettings';

export const useAccountConfiguration = () => {
  const auth = useAuth();
  const { settings } = useSettings();

  const { data: configData } = useQuery({
    queryKey: ['accountConfiguration', auth.user, settings.currentRole],
    queryFn: async () => {
      // if we are impersonating use the impersonated role
      const user = auth.user;
      const loggedInUserId = user?.profile.tenantUserId as string;
      let roleToLoad = user?.profile?.defaultRole as string;

      // if we have switched roles pull
      if (settings.currentRole != '' && settings.currentRole != null) {
        roleToLoad = settings.currentRole;
      }

      // check if we are already impersonating a tenant
      const fromSession = window.sessionStorage.getItem(`impersonate-${loggedInUserId}`);
      if (fromSession) {
        roleToLoad = JSON.parse(fromSession).roleId;
      }

      const res = await getAccountConfiguration(roleToLoad);

      return res.data;
    },
    onSuccess: res => {
      sessionStorage.setItem('account-configurations', JSON.stringify(res));
    }
  });

  /**
   * Get the account configuration from session.
   */
  const getAccountConfigFromSession = async () => {
    const configFromSession = sessionStorage.getItem('account-configurations');

    if (configFromSession) {
      return JSON.parse(configFromSession);
    }

    return {};
  };

  return { getAccountConfigFromSession, configData };
};
