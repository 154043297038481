import { ListItemIcon, ListItemText, SvgIcon, useTheme } from '@mui/material';
import { MenuItem } from './styledComponents';
import AccountCog from './icons/AccountCog';
import { useAppDispatch } from 'src/store/baseHooks';
import { useRouter } from 'next/router';
import { pushNavigationStack } from 'src/store/slices/uiFlowSlice';
import { UserDto } from 'src/models/identity';
import { useGetRolesQuery } from 'src/queries/apps/account';
import useUserHasAdminPrivilege from 'src/@core/hooks/useUserHasAdminPrivilege';
// import usePermission from 'src/@core/hooks/usePermission';

interface UserAccountSettingsProps {
  userData?: UserDto;
  handleClose: (x?: any, y?: any) => void;
}

const UserAccountSettings = ({ userData, handleClose }: UserAccountSettingsProps) => {
  // const { hasPermission } = usePermission();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const theme = useTheme();
  const { data: roles } = useGetRolesQuery({ enabled: true });

  const hasAdminPrivilege = useUserHasAdminPrivilege();

  /**
   * Determines if the user has admin privilege.
   *
   * @return {boolean} - Returns true if the user has admin privilege, false otherwise.
   */
  // const hasAdminPrivilege = () => {
  //   return roles?.some(role => role?.isAdmin ?? false) ?? false;
  // };

  console.log('UserAccountSettings', { userData, roles, admin: hasAdminPrivilege });

  // /**
  //  * Checks if the user has access to the account details.
  //  *
  //  * @return {boolean} true if the user has access, false otherwise
  //  */
  // const userHasAccountAccess = (): boolean => {
  //   return hasPermission('account.account details', 'CanRead');
  // };

  /**
   * Handles the click event.
   *
   * @param {any} e - The event object.
   * @return {void} This function does not return a value.
   */
  const handleClick = (e: any): void => {
    // if (!userHasAccountAccess()) return;

    dispatch(pushNavigationStack(router.asPath));
    router.push(`/uiflow/account`);
    handleClose(e, 'backdropClick');
  };

  // if (!userHasAccountAccess()) return null;

  return (
    <MenuItem sx={{ paddingBlock: '10px' }} onClick={handleClick}>
      <ListItemIcon>
        <SvgIcon>
          <AccountCog productColor={theme.palette.customColors.primaryGradient} />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText sx={{ '& span:hover': { color: theme.palette.customColors.primaryGradient } }}>
        Account Settings
      </ListItemText>
    </MenuItem>
  );
};

export default UserAccountSettings;
