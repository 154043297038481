import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { ClientDto, GetClientQueryParams, GetUserByidQueryParams, UserDto } from 'src/models/identity';
import { QueryProps, baseQueryFn } from 'src/queries/uiflow/baseQueryFunctions';
import { getClients, getUserByIdAsync } from 'src/services/identity';

/**
 * Clients query
 */
export const useGetClientsQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  refetchOnWindowFocus = false,
  refetchOnMount = true,
  staleTime = Infinity,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<ClientDto[], GetClientQueryParams>): UseQueryResult<ClientDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['clients', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getClients(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    refetchOnMount,
    staleTime,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * User Profile Query
 */
export const useGetUserProfileQuery = (
  loggedInUserId: string,
  {
    enabled = true,
    initialData = {},
    onSuccess = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<UserDto, GetUserByidQueryParams>
): UseQueryResult<UserDto> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['userProfile', loggedInUserId, params],
    queryFn: () => baseQueryFn({ serviceFn: () => getUserByIdAsync(loggedInUserId), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};
