import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const useVigoTranslation = () => {
  const { t } = useTranslation();

  const tenantConfig = sessionStorage.getItem('account-configurations');

  const translate = (text?: string) => {
    let tt: string = t(text != null ? text : '');
    if (tt == '') {
      tt = text != null ? text : '';
    }

    if (
      !process.env.NODE_ENV ||
      (process.env.NODE_ENV === 'development' && !i18next.exists(text != undefined ? text : ''))
    ) {
      if (text != '') {
        tt = '_' + tt;
      } else {
        tt = '';
      }
    }

    if (tenantConfig !== null) {
      const obj = JSON.parse(tenantConfig);

      const tenantTranslation = obj.translations.find((x: { label: string | undefined }) => x.label == text);
      if (tenantTranslation) {
        tt = tenantTranslation.tanslatedText;
      }
    }

    if (text === undefined) {
      tt = '';
    }

    return tt;
  };

  return { t: translate };
};
