// ** MUI Imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

// ** Icons Imports
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import MenuIcon from '@mui/icons-material/Menu';

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext';
import { useVigoPage } from 'src/@core/hooks/useVigoPage';

// ** Components
import OmniSearch from 'src/@core/layouts/components/shared-components/OmniSearch';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import React from 'react';
import { useGenerateUIFlowPreview } from 'src/@core/hooks/useGenerateUIFlowPreview';
import { CircularProgress, useTheme } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import RecentHistoryPanel from 'src/layouts/components/panels/recenthistory/RecentHistoryPanel';
import AttachmentsPanel from '../panels/attachments/AttachmentsPanel';
import UserProfilePanel from '../panels/userprofile/index';
import ImpersonationDropdown from 'src/@core/layouts/components/shared-components/ImpersonationDropdown';
import { useGetUserProfileQuery } from 'src/queries/apps/identity';

interface Props {
  hidden: boolean;
  settings: Settings;
  toggleNavVisibility: () => void;
  setShowBackdrop: (val: boolean) => void;
  saveSettings: (values: Settings) => void;
  showHelp: () => void;
}

const AppBarContent = ({ hidden, settings, toggleNavVisibility, setShowBackdrop }: Props) => {
  const { VigoPage, saveVigoPage, pathToTitleMap } = useVigoPage();
  const router = useRouter();
  const theme = useTheme();
  const { user } = useAuth();

  const textColor = () => {
    return `rgba(${theme.palette.customColors.dark}, 0.87)`;
  };

  let company = user?.profile.company as string;

  const loggedInUserId = user?.profile.tenantUserId as string;
  // check if we are already impersonating a tenant
  const fromSession = window.sessionStorage.getItem(`impersonate-${loggedInUserId}`);
  if (fromSession) {
    company = JSON.parse(fromSession).companyName;
  }

  const { data: userData } = useGetUserProfileQuery(loggedInUserId, {});

  useEffect(() => {
    if (!VigoPage.pageTitle) {
      const routeObject = pathToTitleMap.find(obj => obj.path === router.pathname);

      if (routeObject) {
        saveVigoPage({
          pageTitle: routeObject?.title,
          pageSubTitle: ''
        });
      }
    }
  });

  const { generatePreview, isLoading } = useGenerateUIFlowPreview();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
      <Box sx={{ mr: 2, display: 'flex', alignItems: 'center', flex: '1' }}>
        {hidden ? (
          <IconButton sx={{ color: textColor() }} onClick={toggleNavVisibility}>
            <MenuIcon />
          </IconButton>
        ) : null}

        {settings.showUATRecords === true && (
          <img
            src="/images/icons/vigo/uatpill.svg"
            style={{ height: '26px', marginLeft: '-3px' }}
            alt="Flow UAT icon"
          />
        )}
      </Box>

      <Box
        className="actions-right"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          color: textColor()
        }}>
        {settings?.isImpersonating === true && <ImpersonationDropdown />}
        <Box sx={{ display: 'flex' }}>
          {settings?.developerMode && router.pathname === '/uiflow' && (
            <IconButton
              color="inherit"
              onClick={() => {
                generatePreview();
              }}>
              {!isLoading && <ScreenshotMonitorIcon />}
              {isLoading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
            </IconButton>
          )}

          <OmniSearch hidden={hidden} setShowBackdrop={setShowBackdrop} />

          <IconButton sx={{ color: textColor() }}>
            <RecentHistoryPanel />
          </IconButton>

          <AttachmentsPanel />

          {/* <NotificationDropdown settings={settings} /> */}
        </Box>
      </Box>
      <UserProfilePanel userData={userData} company={company} />
    </Box>
  );
};

export default AppBarContent;
