import { useTheme } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * EXAMPLE USE:
 *
 * const {set} = useConfirmDialog();
 *
 * const handleConfirmDialog = async () => {
    const result = await set(
      'Do you wish to save?',
      () => //console.log('confirm confirmed'),
      () => //console.log('confirm denied')
    );

    //console.log({ result });
  };
 *
 */

export const useConfirmDialog = (showDenyButton = true, confirmButtonText = 'Yes', denyButtonText = 'No') => {
  const theme = useTheme();

  if(theme.palette.mode === 'dark'){
    const set = (title: string, confirmedCallback?: () => any, deniedCallback?: () => any, footer?: string) =>
    Swal.fire({
      text: Boolean(title) ? title : 'Confirm message here!',
      footer: footer,
      showDenyButton,
      confirmButtonText,
      denyButtonText,
      reverseButtons: true,
      background: "#1c1c1c",
      color: "#f2f2f2",
      customClass: {
        confirmButton: 'swal-confirm-button',
        denyButton: 'swal-deny-button'
      }
    }).then(result => {
      if (result.isConfirmed) {
        typeof confirmedCallback === 'function' && confirmedCallback();

        return true;
      } else if (result.isDenied) {
        typeof deniedCallback === 'function' && deniedCallback();

        return false;
      } else {
        return false;
      }
    });
    
    return { set };
  }else {
    const set = (title: string, confirmedCallback?: () => any, deniedCallback?: () => any, footer?: string) =>
    Swal.fire({
      text: Boolean(title) ? title : 'Confirm message here!',
      footer: footer,
      showDenyButton,
      confirmButtonText,
      denyButtonText,
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal-confirm-button',
        denyButton: 'swal-deny-button'
      }
    }).then(result => {
      if (result.isConfirmed) {
        typeof confirmedCallback === 'function' && confirmedCallback();

        return true;
      } else if (result.isDenied) {
        typeof deniedCallback === 'function' && deniedCallback();

        return false;
      } else {
        return false;
      }
    });
    
    return { set };
  }
  
  

  
};
