// ** React Imports
import { useEffect, useState } from 'react';

// ** Axios Import

// ** Type Import
import { NavGroup, NavLink, VerticalNavItemsType } from 'src/@core/layouts/types';

import { useVigoPage } from 'src/@core/hooks/useVigoPage';
import { PathToTitle } from 'src/@core/context/vigoPageContext';
import { useAccountConfiguration } from '../../../@core/hooks/useAccountConfiguration';

const ServerSideNavItems = () => {
  // ** State
  const [menuItems, setMenuItems] = useState<VerticalNavItemsType>([]);
  const { setPathToTitleMap } = useVigoPage();
  const { configData } = useAccountConfiguration();

  useEffect(() => {
    (async () => {
      if (configData) {
        createMenuFromConfiguration(JSON.stringify(configData));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);

  function finalMenuArray(items: VerticalNavItemsType): VerticalNavItemsType {
    return items.map((item: any) => {
      if (item.icon) {
        // @ts-ignore
        item.icon = item.icon;

        if (item.children) {
          finalMenuArray(item.children);
        }

        if (item.path) {
          return item as NavLink;
        } else {
          return item as NavGroup;
        }
      }
      if (item.path) {
        return item as NavLink;
      } else {
        return item as NavGroup;
      }
    });
  }

  function createMenuFromConfiguration(configuration: string | null) {
    const jobj = JSON.parse(configuration!);
    if (jobj != null && Object.keys(jobj).length > 0) {
      const flattenedMenuItems: PathToTitle[] = [];
      flatten(jobj.menu.menuItems, flattenedMenuItems);
      setPathToTitleMap(flattenedMenuItems);
      const menuArr = finalMenuArray(jobj.menu.menuItems as VerticalNavItemsType);
      setMenuItems(menuArr);
    }
  }

  function flatten(data: any[], outputArray: any[]) {
    data.forEach(function (element) {
      if (Array.isArray(element.children) && element.children.length > 0) {
        flatten(element.children, outputArray);
      } else {
        outputArray.push({
          path: element.path,
          title: element.title,
          icon: element.icon
        });
      }
    });
  }

  return menuItems;
};

export default ServerSideNavItems;
