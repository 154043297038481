import ProfileHeader from './ProfileHeader';
import { Divider, Menu } from './styledComponents';
import UserRoleMenu from './UserRoleMenu';
import UserSettings from './UserSettings';
import UserAccountSettings from './UserAccountSettings';
import DarkModeToggler from './DarkModeToggler';
import UserLogout from './UserLogout';
import ProfileFooter from './ProfileFooter';
import { UserDto } from 'src/models/identity';
import ReleaseNotesListItem from './ReleaseNotes';
import { Products, useGetProductLevel } from 'src/@core/utils/getProductLevel';

interface UserProfileMenuProps {
  userData?: UserDto;
  company?: string;
  anchorEl: (EventTarget & Element) | null;
  handleClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
}

const UserProfileMenu = ({ userData, company, anchorEl, handleClose }: UserProfileMenuProps) => {
  const prod = useGetProductLevel();

  /**
   * A handler function for when the dropdown is closed.
   *
   * @param {Event} e - The event object.
   * @param {string} reason - The reason why the dropdown was closed.
   *        Possible values are 'backdropClick' or 'escapeKeyDown'.
   * @return {void} This function does not return anything.
   */
  const handleDropdownClose = (e: Event, reason: 'backdropClick' | 'escapeKeyDown'): void => {
    handleClose(e, reason);
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDropdownClose}>
      <ProfileHeader userData={userData} company={company} />

      <Divider />

      <UserRoleMenu data={userData ?? {}} />

      <UserSettings userData={userData} handleClose={handleDropdownClose} />

      <UserAccountSettings userData={userData} handleClose={handleDropdownClose} />

      <DarkModeToggler />

      <Divider />

      {prod !== Products.MyPortal && <ReleaseNotesListItem />}

      <UserLogout />

      <ProfileFooter />
    </Menu>
  );
};

export default UserProfileMenu;
