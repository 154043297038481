export enum ServiceLocation {
  adminService = '/adminservice/api/v1',
  registrationService = '/registration/api/v1',
  ordersService = '/orderservice/api/v1',
  masterdata = '/masterdata/api/v1',
  addOnService = '/addOnService/api/v1',
  addressService = '/address/api/v1',
  systemadmin = '/systemadmin/api/v1',
  uiflowdata = '/uiflow/api/v1',
  account = '/account/api/v1',
  workFlowData = '/workFlowData/api/v1',
  systemconfig = '/systemconfig/api/v1',
  automation = '/automation/api/v1',
  dashboardLayout = '/dashboardLayout/api/v1',
  widgetData = '/widgetData/api/v1',
  identity = '/identity/api/v1',
  relationshipmanagement = '/relationshipmanagement/api/v1',
  routedetermination = '/routedetermination/api/v1',
  salesorder = '/salesorder/api/v1',
  billing = '/billing/api/v1',
  newsfeed = '/newsfeed/api/v1',
  attachment = '/attachment/api/v1',
  tenantadmin = '/tenantadmin/api/v1',
  search = '/search/api/v1',
  messaging = '/messaging/api/v1',
  shorturl = '/shorturl/api/v1',
  deviceManagement = '/devicemanagement/api/v1',
  import = '/import/api/v1'
}

export const ConvertServiceLocationToEnum = (value?: string): any => {
  const typedServiceLocation = value as keyof typeof ServiceLocation;
  const typedColor = ServiceLocation[typedServiceLocation];

  return typedColor;
};
