import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { GetAxiosInstance } from 'src/services/baseService';
import { ServiceLocation } from 'src/services/serviceLocationsAxios';

const useFileUploader = () => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<any>(null);

  const uploadAttachment = useMutation(
    (formData: any): any => {
      const retval = GetAxiosInstance(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'multipart/form-data'
      ).post<any>(ServiceLocation.attachment + '/attachment', formData, {
        onUploadProgress: progressEvent => {
          if (progressEvent.total) {
            setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          }
        }
      });

      return retval;
    },
    {
      onMutate: async () => {
        setError('mutated');
      },
      onError: () => {
        setError('Error attaching document');
      },
      onSuccess: () => {
        setError('');
      }
    }
  );

  return { uploadAttachment, progress, error };
};

export default useFileUploader;
