import { useState, useEffect } from 'react';
import { RoleDto } from 'src/models/account';
import { useGetRolesQuery } from 'src/queries/apps/account';
import { useGetUserProfileQuery } from 'src/queries/apps/identity';
import { useAppSelector } from 'src/store/baseHooks';

/**
 * Determine if user has admin privilege by checking if they have any assigned roles that are marked as admin.
 *
 * @returns boolean
 */
const useUserHasAdminPrivilege = (): boolean => {
  const [hasAdminPrivilege, setHasAdminPrivilege] = useState(false);

  const loggedInUserId = useAppSelector(state => state?.auth?.user?.profile?.tenantUserId ?? '') ?? '';

  const { data: userData } = useGetUserProfileQuery(loggedInUserId as string, { enabled: !!loggedInUserId });
  const { data: roles } = useGetRolesQuery({ enabled: true });

  /**
   * Get user role dtos's
   */
  const userRoles = roles?.filter((role: RoleDto) => userData?.roles?.includes(role?.id ?? ''));

  useEffect(() => {
    /**
     * Check if user has admin privileges
     */
    if (userRoles?.some(role => role?.isAdmin ?? false)) {
      return setHasAdminPrivilege(true);
    }

    return setHasAdminPrivilege(false);
  }, [roles, userRoles, loggedInUserId, userData]);

  return hasAdminPrivilege;
};

export default useUserHasAdminPrivilege;
