import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  GetAccountByidSubscriptionsQueryParams,
  GetMenuByidQueryParams,
  GetMenuItemByidQueryParams,
  GetMenuItemComponentByidQueryParams,
  GetMenuItemComponentQueryParams,
  GetMenuItemQueryParams,
  GetMenuQueryParams,
  GetProductLicenceScopeQueryParams,
  GetRoleByidPermissionsQueryParams,
  GetRoleQueryParams,
  MenuDto,
  MenuItemComponentDto,
  MenuItemDto,
  PermissionDto,
  ProductLicenceScopeDto,
  RoleDto,
  SubscriptionDto
} from 'src/models/account';
import { QueryProps, baseQueryFn } from 'src/queries/uiflow/baseQueryFunctions';
import {
  getAccountSubscriptionsByAccountId,
  getMenuById,
  getMenuItemById,
  getMenuItemComponents,
  getMenuItemComponentById,
  getMenuItems,
  getMenus,
  getProductLicenceScopes,
  getRoleByIdPermissions,
  getRoles
} from 'src/services/account';

/**
 * Get Menus Query
 */
export const useGetMenusQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<MenuDto[], GetMenuQueryParams>): UseQueryResult<MenuDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['menus', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getMenus(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Get Menu by Id Query
 */
export const useGetMenuByIdQuery = (
  id: string,
  {
    enabled = true,
    initialData = [],
    onSuccess = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<MenuDto, GetMenuByidQueryParams>
): UseQueryResult<MenuDto> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['menu', id, params],
    queryFn: () => baseQueryFn({ serviceFn: () => getMenuById(id, params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Get Menu Items Query
 */
export const useGetMenuItemsQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<MenuItemDto[], GetMenuItemQueryParams>): UseQueryResult<MenuItemDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['menuItems', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getMenuItems(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Get Menu Item Components Query
 */
export const useGetMenuItemComponentsQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<MenuItemComponentDto[], GetMenuItemComponentQueryParams>): UseQueryResult<MenuItemComponentDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['menuItemComponents', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getMenuItemComponents(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Get Menu Item by Id Query
 */
export const useGetMenuItemByIdQuery = (
  id: string,
  {
    enabled = true,
    initialData = {},
    onSuccess = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<MenuItemDto, GetMenuItemByidQueryParams>
): UseQueryResult<MenuItemDto> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['menuItem', id, params],
    queryFn: () => baseQueryFn({ serviceFn: () => getMenuItemById(id, params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Get Menu Item Component by Id Query
 */
export const useGetMenuItemComponentByIdQuery = (
  id: string,
  {
    enabled = true,
    initialData = {},
    onSuccess = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<MenuItemComponentDto, GetMenuItemComponentByidQueryParams>
): UseQueryResult<MenuItemComponentDto> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['menuItemComponent', id, params],
    queryFn: () => baseQueryFn({ serviceFn: () => getMenuItemComponentById(id, params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Account Subscriptions Query
 */
export const useGetAccountSubscriptionsQuery = (
  id: string,
  {
    enabled = true,
    initialData = [],
    onSuccess = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<SubscriptionDto[], GetAccountByidSubscriptionsQueryParams>
): UseQueryResult<SubscriptionDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['accountSubscriptions', id],
    queryFn: () =>
      baseQueryFn({ serviceFn: () => getAccountSubscriptionsByAccountId(id, params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Roles Query
 */
export const useGetRolesQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<RoleDto[], GetRoleQueryParams>): UseQueryResult<RoleDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['roles', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getRoles(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Role By Id Permissions Query
 */
export const useGetRoleByIdPermissionsQuery = (
  id: string,
  {
    enabled = true,
    initialData = [],
    onSuccess = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<PermissionDto[], GetRoleByidPermissionsQueryParams>
): UseQueryResult<PermissionDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['rolePermissions', id, params],
    queryFn: () => baseQueryFn({ serviceFn: () => getRoleByIdPermissions(id, params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Product Licence Scopes
 */
export const useGetProductLicenceScopesQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<ProductLicenceScopeDto[], GetProductLicenceScopeQueryParams>): UseQueryResult<
  ProductLicenceScopeDto[]
> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['productLicenceScopes', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getProductLicenceScopes(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};
