import { useAuth } from 'react-oidc-context';
import { useSettings } from './useSettings';

export const useVigoLogout = () => {
  const { settings, saveSettings } = useSettings();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { signoutRedirect, removeUser } = useAuth();

  /**
   * Removes all customer configuration rules from the local storage.
   *
   * @param {none} - This function does not take any parameters.
   * @return {none} - This function does not return any value.
   */
  const removeCustomerRulesConfig = (): void => {
    Object.keys(localStorage ?? {})
      .filter(key => key.startsWith('CustomerConfig-'))
      .forEach(key => localStorage.removeItem(key));
  };

  /**
   * Removes the 'account-configurations' item from session storage,
   * removes customer rules configuration, saves settings with an
   * updated current role, and calls the 'signoutRedirect' function.
   *
   * @return {void} This function does not return anything.
   */
  const handleLogout = (): void => {
    sessionStorage.removeItem('account-configurations');
    removeCustomerRulesConfig();
    saveSettings({ ...settings, currentRole: '' as string });

    // remove the session storage for the token
    // removeUser();
    signoutRedirect();
  };

  return { handleLogout };
};
