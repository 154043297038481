/* eslint-disable @typescript-eslint/no-unused-vars */
// ** React Imports
import { SyntheticEvent, ChangeEvent, Fragment, useCallback, useEffect, useRef, useState, createElement } from 'react';

// ** Next Import
import { useRouter } from 'next/router';

// ** MUI Imports
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Box, { BoxProps } from '@mui/material/Box';
import ClickAway from '@mui/material/ClickAwayListener';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemButton from '@mui/material/ListItemButton';
import MuiAutocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import dynamic from 'next/dynamic';

// ** Icons Imports
import Close from 'mdi-material-ui/Close';
import Magnify from 'mdi-material-ui/Magnify';

// ** Configs Imports
import themeConfig from 'src/configs/themeConfig';

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings';

// ** Custom Components Imports
import UserIcon from 'src/layouts/components/UserIcon';

// ** API Icon Import with object
import { autocompleteIconObj } from './autocompleteIconObj';
import { Icon, Stack, Typography } from '@mui/material';
import { GetSearchSearchQueryParams, OmniSearchResultItemDto } from 'src/models/search';
import { getOmniSearchResults } from 'src/services/search';
import debounce from 'lodash/debounce';
import { useDidMountEffect } from 'src/@core/hooks/useDidMountEffect';
import { useAppDispatch } from 'src/store/baseHooks';
import { pushNavigationStack } from 'src/store/slices/uiFlowSlice';
import { Products, useGetProductLevel } from 'src/@core/utils/getProductLevel';

interface Props {
  hidden?: boolean;
  setShowBackdrop: (val: boolean) => void;
}

// ** Styled component for search in the appBar
const SearchBox = styled(Box)<BoxProps>(({ theme }) => ({
  right: 0,
  width: '100%',
  position: 'absolute',
  top: 'calc(-100% - 1px)',
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  zIndex: theme.zIndex.appBar + 1,
  transition: 'top .25s ease-in-out',
  backgroundColor: theme.palette.background.paper
}));

// ** Styled Autocomplete component
const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  height: '100%',
  '& .MuiFormControl-root': {
    height: '100%',
    '& .MuiInputBase-root': {
      height: '100%',
      padding: theme.spacing(0, 5),
      '& fieldset': {
        border: 0
      }
    }
  },
  '& + .MuiAutocomplete-popper': {
    paddingTop: theme.spacing(2.5),
    '& .MuiAutocomplete-paper': {
      borderRadius: 5
    },
    '& .MuiAutocomplete-listbox': {
      paddingTop: 0,
      maxHeight: 'calc(100vh - 15rem)'
    },
    '& .MuiListSubheader-root': {
      fontSize: '0.75rem',
      fontWeight: 'normal',
      lineHeight: 'normal',
      textTransform: 'uppercase',
      padding: theme.spacing(5, 5, 2.5),
      color: theme.palette.text.disabled
    }
  }
}));

// ** Styled component for the images of files in search popup
const ImgFiles = styled('img')(({ theme }) => ({
  height: 24,
  marginRight: theme.spacing(2.5)
}));

const IconRenderer = ({ searchresult }: { searchresult: any }) => {
  let icon = searchresult.icon;
  if (!searchresult.icon) {
    // @ts-ignore
    icon = dynamic(() => import('mdi-material-ui').then(mod => mod[['AccountCircleOutline']]));
  } else {
    // @ts-ignore
    icon = dynamic(() => import('mdi-material-ui').then(mod => mod[[searchresult.icon]]));
  }

  return <Icon sx={{ mr: 2.5, width: 35, height: 35 }}>{createElement(icon)}</Icon>;
};

const OmiSearchComponent = ({ setShowBackdrop }: Props) => {
  // ** States
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [options, setOptions] = useState<OmniSearchResultItemDto[]>([]);
  const [autocompleteKey, setAutocompleteKey] = useState<number>(0);
  const [openSearchBox, setOpenSearchBox] = useState<boolean>(false);
  const [openAutocompletePopup, setOpenAutocompletePopup] = useState<boolean>(false);

  // ** Hooks & Vars
  const router = useRouter();
  const { settings } = useSettings();
  const { skin, appBar, layout } = settings;
  const wrapper = useRef<HTMLDivElement>(null);

  const prod = useGetProductLevel();

  const dispatch = useAppDispatch();

  const codes: { [key: string]: boolean } = { Slash: false, ControlLeft: false, ControlRight: false }; // eslint-disable-line

  useDidMountEffect(() => {
    getOptionsDelayed(searchValue);

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getOptionsDelayed = useCallback(
    debounce(searchValue => {
      const params: GetSearchSearchQueryParams = {
        searchString: searchValue,
        max: 10
      };

      getOmniSearchResults(params, prod === Products.MyPortal ? 'Connect' : undefined).then(response => {
        setOptions(response.data.results ?? []);
      });
    }, 500),
    []
  );

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  // Handle ESC & shortcut keys keydown events
  const handleKeydown = useCallback(
    event => {
      // ** ESC key to close searchbox
      if (openSearchBox && event.keyCode === 27) {
        setShowBackdrop(false);
        setOpenSearchBox(false);
      }

      // ** Shortcut keys to open searchbox (Ctrl + /)
      if (event.code === 'Slash') {
        codes.Slash = true;
      }
      if (event.code === 'ControlLeft') {
        codes.ControlLeft = true;
      }
      if (event.code === 'ControlRight') {
        codes.ControlRight = true;
      }
      if (!openSearchBox && (codes.ControlLeft || codes.ControlRight) && codes.Slash) {
        setShowBackdrop(true);
        setOpenSearchBox(true);
      }
    },
    [codes, openSearchBox, setShowBackdrop]
  );

  // Handle shortcut keys keyup events
  const handleKeyUp = useCallback(
    event => {
      if (event.code === 'Slash') {
        codes.Slash = false;
      }
      if (event.code === 'ControlLeft') {
        codes.ControlLeft = false;
      }
      if (event.code === 'ControlRight') {
        codes.ControlRight = false;
      }
    },
    [codes]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp, handleKeydown]);

  // Handle all states
  const handleAllStates = (value: boolean) => {
    setSearchValue('');
    setShowBackdrop(value);
    setOpenSearchBox(value);
    setOpenAutocompletePopup(value);
  };

  // Handle input change on Autocomplete component
  const handleInputChange = (value: string) => {
    if (value.length) {
      setOpenAutocompletePopup(true);
    } else {
      setOpenAutocompletePopup(false);
    }
  };

  // Handle click event on a list item in search result
  const handleOptionClick = (obj?: OmniSearchResultItemDto) => {
    setSearchValue('');
    handleAllStates(false);

    dispatch(pushNavigationStack(router.asPath));

    // hanle the fact we have a uiFlowName of consignment but the resultType is order
    const uiFlowName = obj?.resultType?.toLowerCase() === 'order' ? 'consignment' : obj?.resultType?.toLowerCase();
    router.push(`/uiflow/${uiFlowName ?? ''}/${obj?.entityId ?? ''}?drillDown=1`);
  };

  // Handle option change on Autocomplete component
  const handleAutocompleteChange = (event: SyntheticEvent, obj: OmniSearchResultItemDto | unknown) => {
    setAutocompleteKey(autocompleteKey + 1);
    if ((obj as OmniSearchResultItemDto).uiFlow) {
      handleOptionClick(obj as OmniSearchResultItemDto);
    }
  };

  // Render all options for the search
  const RenderOptions = (option: OmniSearchResultItemDto) => {
    const {
      additionalColumn1,
      additionalColumn2,
      additionalColumn3,
      additionalColumn4,
      additionalColumn5,
      additionalColumn6,
      additionalColumn7,
      additionalColumn8,
      additionalColumn9,
      additionalColumn10,
      createdDate,
      entityId,
      icon,
      identifier,
      resultType,
      uiFlow
    } = option;

    if (resultType === 'Order') {
      return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {<IconRenderer searchresult={option} />}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Stack direction="row">
                <Typography sx={{ fontSize: '1.2rem' }}>{identifier}</Typography>
                <Typography sx={{ fontSize: '1.2rem', ml: '10px' }}>{additionalColumn1}</Typography>
              </Stack>
              <Stack direction={'row'}>
                <Typography variant="caption" sx={{ fontSize: '1rem' }}>
                  {additionalColumn3},
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '1rem', ml: '3px' }}>
                  {additionalColumn4},
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '1rem', ml: '3px' }}>
                  {additionalColumn4}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '1rem', ml: '3px' }}>
                  {additionalColumn5}
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Typography variant="caption" sx={{ fontSize: '1.2rem', ml: '3px' }}>
            {new Date(additionalColumn6 ?? '').toLocaleDateString()}
          </Typography>
        </Box>
      );
    } else if (resultType === 'Customer') {
      return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {<IconRenderer searchresult={option} />}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Stack direction="row">
                <Typography sx={{ fontSize: '1.2rem' }}>{identifier}</Typography>
              </Stack>
              <Stack direction={'row'}>
                <Typography variant="caption" sx={{ fontSize: '1rem' }}>
                  {additionalColumn1}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '1rem', ml: '10px' }}>
                  {additionalColumn2}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '1rem', ml: '10px' }}>
                  {additionalColumn3}
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Typography variant="caption" sx={{ fontSize: '1.2rem', ml: '3px' }}>
            {new Date(createdDate ?? '').toLocaleDateString()}
          </Typography>
        </Box>
      );
    } else if (resultType === 'Invoice') {
      return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {<IconRenderer searchresult={option} />}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Stack direction="row">
                <Typography sx={{ fontSize: '1.2rem' }}>{identifier}</Typography>
              </Stack>
              <Stack direction={'row'}>
                <Typography variant="caption" sx={{ fontSize: '1rem' }}>
                  {additionalColumn1}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '1rem', ml: '3px' }}>
                  {additionalColumn2}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '1.2rem', ml: '20px', fontWeight: '900' }}>
                  {additionalColumn3}
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Typography variant="caption" sx={{ fontSize: '1.2rem', ml: '3px' }}>
            {new Date(createdDate ?? '').toLocaleDateString()}
          </Typography>
        </Box>
      );
    } else {
      return null;
    }
  };
  if (!isMounted) {
    return null;
  } else {
    return (
      <ClickAway onClickAway={() => handleAllStates(false)}>
        <Box ref={wrapper}>
          <IconButton
            color="inherit"
            onClick={() => {
              setShowBackdrop(true);
              setOpenSearchBox(true);
            }}>
            <Magnify />
          </IconButton>

          <SearchBox
            sx={{
              ...(openSearchBox ? { top: 0 } : {}),
              height: theme =>
                (theme.mixins.toolbar.minHeight as number) - (layout === 'horizontal' || skin === 'bordered' ? 1 : 0),
              ...(layout === 'horizontal'
                ? { mx: 6, width: theme => `calc(100% - ${theme.spacing(6)} * 2)` }
                : { width: '100%' })
            }}>
            <Autocomplete
              autoHighlight
              disablePortal
              options={options}
              id="appBar-search"
              key={autocompleteKey}
              open={openAutocompletePopup}
              noOptionsText="No results found!"
              filterOptions={x => x}
              onChange={handleAutocompleteChange}
              onClose={() => handleAllStates(false)}
              onInputChange={(event, value: string) => handleInputChange(value)}
              groupBy={(option: OmniSearchResultItemDto | unknown) =>
                (option as OmniSearchResultItemDto).resultType ?? ''
              }
              getOptionLabel={(option: OmniSearchResultItemDto | unknown) =>
                (option as OmniSearchResultItemDto).identifier ?? ''
              }
              renderOption={(props, option: OmniSearchResultItemDto | unknown) => (
                <ListItem
                  {...props}
                  sx={{ p: '0 !important' }}
                  key={(option as OmniSearchResultItemDto).identifier}
                  onClick={() =>
                    (option as OmniSearchResultItemDto).uiFlow
                      ? handleOptionClick(option as OmniSearchResultItemDto)
                      : handleOptionClick()
                  }>
                  <ListItemButton sx={{ padding: theme => theme.spacing(2.5, 5) }}>
                    {RenderOptions(option as OmniSearchResultItemDto)}
                  </ListItemButton>
                </ListItem>
              )}
              renderInput={(params: AutocompleteRenderInputParams) => {
                const scrollPosition = document.documentElement.scrollTop;

                return (
                  <TextField
                    {...params}
                    {...(appBar === 'fixed' ? { onFocus: () => window.scrollTo(0, scrollPosition) } : {})}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value)}
                    inputRef={input => {
                      if (input) {
                        if (openSearchBox) {
                          input.focus();
                        } else {
                          input.blur();
                        }
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start" sx={{ color: 'text.primary' }}>
                          <Magnify />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() => handleAllStates(false)}
                          sx={{ cursor: 'pointer', color: 'text.primary' }}>
                          <Close fontSize="small" />
                        </InputAdornment>
                      )
                    }}
                  />
                );
              }}
            />
          </SearchBox>
        </Box>
      </ClickAway>
    );
  }
};

export default OmiSearchComponent;
