import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { useVigoTranslation } from './useVigoTranslation';

/**
 * Toast Notifications
 */

export const useVigoToast = ({}) => {
  const theme = useTheme();
  const { t } = useVigoTranslation();

  const handleServerErrorMessage = (err: any, message: string) => {
    let formattedErrorMessage = '';

    // For standard CRUD endpoints
    if (err.response) {
      if (err.response.data) {
        const hasErrors = Boolean(err.response.data.errors);

        if (hasErrors) {
          let objectStr = '';
          Object.keys(err.response.data.errors).map(prop => {
            if (objectStr === '') {
              objectStr = err.response.data.errors[prop].join(', ');
            } else {
              objectStr = objectStr + '\n' + err.response.data.errors[prop].join(', ');
            }
          });

          formattedErrorMessage = objectStr;
        }

        if (err.response.data.message) {
          return t(`${message}` + '\n' + `${err.response.data.message}`);
        }

        return t(`${message}` + '\n' + `${formattedErrorMessage}`);
      }
    }

    return t(`${message}`);
  };

  const vigoToast = {
    success: (message = '', options = {}) => {
      if (Object.keys(options).length === 0) {
        options =
          theme.palette.mode === 'dark'
            ? {
                style: {
                  padding: '16px',
                  minWidth: '250px',
                  backgroundColor: '#171717',
                  color: '#fff'
                }
              }
            : {
                padding: '16px',
                minWidth: '250px'
              };
      }

      toast.success(t(`${message}`), options);
    },
    error: (message = '', options = {}) => {
      if (Object.keys(options).length === 0) {
        options =
          theme.palette.mode === 'dark'
            ? {
                style: {
                  padding: '16px',
                  minWidth: '250px',
                  backgroundColor: '#171717',
                  color: '#fff'
                }
              }
            : {
                padding: '16px',
                minWidth: '250px'
              };
      }

      toast.error(t(`${message}`), options);
    },
    loading: (message = '', options = {}) => {
      if (Object.keys(options).length === 0) {
        options =
          theme.palette.mode === 'dark'
            ? {
                style: {
                  padding: '16px',
                  minWidth: '250px',
                  backgroundColor: '#171717',
                  color: '#fff'
                }
              }
            : {
                padding: '16px',
                minWidth: '250px'
              };
      }

      return toast.loading(t(`${message}`), options);
    },
    dismiss: () => {
      toast.dismiss();
    },

    promise: (
      loadingMessage: any,
      sucessMessage: any,
      errorMessage: any,
      useServerErrorMessage: boolean,
      callBack: Promise<any>,
      options = {}
    ) => {
      if (Object.keys(options).length === 0) {
        options =
          theme.palette.mode === 'dark'
            ? {
                style: {
                  padding: '16px',
                  minWidth: '250px',
                  backgroundColor: '#171717',
                  color: '#fff'
                }
              }
            : {
                padding: '16px',
                minWidth: '250px'
              };
      }

      toast.promise(
        callBack,
        {
          loading: t(`${loadingMessage}`),
          success: t(`${sucessMessage}`),
          error: useServerErrorMessage ? err => handleServerErrorMessage(err, errorMessage) : t(`${errorMessage}`)
        },
        options
      );
    }
  };

  return { vigoToast };
};
