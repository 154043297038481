import { useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/baseHooks';
import { useQuery } from '@tanstack/react-query';
import { GetAxiosInstance } from 'src/services/baseService';
import { ServiceLocation } from 'src/services/serviceLocationsAxios';
import { AppBarContext } from '../context/appBarContext';
import { useGetAllowedAttachmentTypesQuery, useGetAttachmentTypesQuery } from 'src/queries/uiflow/attachments';
import { setSelectedEntityId, setSelectedEntityIds } from 'src/store/slices/attachmentsDataSlice';

export const useAttachmentPanel = () => {
  const [attachmentsEnabled, setAttachmentsEnabled] = useState<boolean>(false);

  const { attachmentCount, dispatch } = useContext(AppBarContext);

  let attachmentDtoId = useAppSelector(state => state.attachmentsData.attachmentDtoId);
  const selectedEntityId = useAppSelector(state => state.attachmentsData.selectedEntityId);
  const reduxDispatch = useAppDispatch();

  // we dont have am attachent Dto ID we can default to order as this is currently our only attachment DTO
  if (!attachmentDtoId) {
    attachmentDtoId = '8af48d21-1212-4df6-be73-32d1cf7c5988';
  }

  /**
   * Attachments Allowed Query
   */
  useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['AttachmentsAllowed', attachmentDtoId, selectedEntityId, ServiceLocation.attachment],
    queryFn: async () => {
      const res = await GetAxiosInstance(undefined, undefined, undefined, undefined, undefined, undefined, true).get(
        `${ServiceLocation.attachment}/Dto/${attachmentDtoId}?ignoreOwner=true`
      );

      return res.data.allowAttachments;
    },
    enabled: Boolean(attachmentDtoId),
    initialData: false,
    onSuccess: data => {
      setAttachmentsEnabled(Boolean(selectedEntityId && data));
    }
  });

  /**
   * Get allowed attachment types
   */
  const { data: allowedAttachmentTypes = [] } = useGetAllowedAttachmentTypesQuery(attachmentDtoId, {
    initialData: [],
    enabled: attachmentDtoId !== undefined && attachmentDtoId !== null && attachmentDtoId !== '' && attachmentsEnabled,
    refetchOnWindowFocus: false
  });

  /**
   * Get attachment types
   */
  const { data: attachmentTypes = [] } = useGetAttachmentTypesQuery({
    initialData: [],
    enabled: attachmentDtoId !== undefined && attachmentDtoId !== null && attachmentDtoId !== '' && attachmentsEnabled,
    refetchOnWindowFocus: false
  });

  /**
   * Reset the attachment count.
   * - Will set attachment count to null so the number badge is hidden.
   * - Will reset the selected entity id so that the attachment panel is disabled.
   */
  const resetAttachmentCount = () => {
    dispatch({ type: 'setAttachmentCount', payload: null });
    reduxDispatch(setSelectedEntityId(''));
    reduxDispatch(setSelectedEntityIds([]));
  };

  return {
    allowedAttachmentTypes,
    attachmentTypes,
    attachmentCount,
    attachmentsEnabled,
    setAttacmentCount: dispatch,
    resetAttachmentCount
  };
};
