import { Box, IconButton, Tooltip } from '@mui/material';
import { VigoAttachment } from '../..';
import DeleteIcon from '@mui/icons-material/Delete';
import { Document, Page } from 'react-pdf';
import styles from '../../vigofiledrop.module.css';
import { ImageOff } from 'mdi-material-ui';
import Search from '@mui/icons-material/Search';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { AttachmentDto } from 'src/models/attachment';
import RenderTiff from './RenderTiff';

interface FileThumbnailProps {
  file: VigoAttachment;
  index: number;
  openFileViewer: () => void;
  openFileTypeMenu: (event: any) => void;
  handleDeleteFile: (file: VigoAttachment, index: number) => void;
  docTypeMenuOpen: boolean;
  readOnly?: boolean;
}

export const FileThumbnail = ({
  file,
  index,
  openFileViewer,
  handleDeleteFile,
  readOnly = false
}: FileThumbnailProps) => {
  const buildPreview = (file: VigoAttachment) => {
    /**
     * File is of type TIFF
     */
    if ((file as VigoAttachment)?.type == 'image/tiff' || (file as AttachmentDto)?.fileType == 'image/tiff') {
      return <RenderTiff file={file} />;
    }

    /**
     * File has a thumbnail property (file has come from server?)
     */
    if (Object.hasOwn(file, 'thumbnail')) {
      return (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'grid',
            placeContent: 'center'
          }}>
          <img
            src={`data:${(file as any).fileType};base64,${file.thumbnail}`}
            alt={file.name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              if (currentTarget?.parentNode) {
                currentTarget.parentElement!.innerHTML =
                  '<p style="text-align: center; width: 100%">Preview Not Available</p>';
              }
              currentTarget.remove();
            }}
            loading="lazy"
            className={styles.preview_image}
          />
        </Box>
      );
    }

    /**
     * File is of PDF type
     * - check property 'type' if VigoAttachment, or 'fileType' if AttachmentDto
     */
    if ((file as VigoAttachment)?.type == 'application/pdf' || (file as AttachmentDto)?.fileType == 'application/pdf') {
      return (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            '& > * ': { width: '100% !important', height: '100% !important' }
          }}>
          <Document className={styles.imageListItem} file={file}>
            <Page pageNumber={1} width={200} />
          </Document>
        </Box>
      );
    }

    /**
     * File is of image type
     */
    if ((file as VigoAttachment)?.type?.match(/image\/.*/) || (file as AttachmentDto)?.fileType?.match(/image\/.*/)) {
      return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%', display: 'grid', placeContent: 'center' }}>
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              if (currentTarget?.parentNode) {
                currentTarget.parentElement!.innerHTML =
                  '<p style="text-align: center; width: 100%">Preview Not Available</p>';
              }
              currentTarget.remove();
            }}
            loading="lazy"
            className={styles.preview_image}
          />
        </Box>
      );
    }

    /**
     * Fallback where no preview can be created
     */

    return (
      <Box>
        <ImageOff />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: '#acacac',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '23%',
        height: '100px',
        overflow: 'hidden',
        objectFit: 'cover',
        borderRadius: '6px',
        boxShadow: '0px 3px 8px -1px rgba(0,0,0,.8)',
        '&:hover': { cursor: 'pointer' }
      }}>
      {buildPreview(file)}

      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',

          '&:hover': { backgroundColor: 'rgba(0,0,0,0.6)' },
          '&:hover > button': { display: 'block' }
        }}>
        <Tooltip title="View" componentsProps={{ tooltip: { sx: { color: '#fff' } } }}>
          <IconButton
            id="viewfile-button"
            onClick={openFileViewer}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-40%, -40%)',
              display: 'none'
            }}>
            <Search
              sx={{
                fontSize: '1.5rem',
                color: '#fff',
                '&:hover': { color: theme => theme.palette.customColors.primaryGradient }
              }}
            />
          </IconButton>
        </Tooltip>
        {!readOnly && (
          <Tooltip
            title="Delete"
            placement="bottom"
            componentsProps={{ tooltip: { sx: { color: '#fff', position: 'absolute', top: -20, left: -25 } } }}
            PopperProps={{ sx: { marginTop: '-100px' } }}>
            <IconButton
              id="deletefile-button"
              sx={{ position: 'absolute', top: 0, right: 0, display: 'none' }}
              onClick={() => handleDeleteFile(file, index)}>
              <DeleteIcon
                htmlColor="#fff"
                sx={{
                  '&:hover': { color: theme => theme.palette.customColors.primaryGradient }
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
